import dataPagination from "./dataPagination"

/**
 * Append Ajax Response data to default container
 * 
 * @param array responseData 
 */

const dataCaseList = (responseData) => {
    let data = ''
    responseData.forEach(caseHistory => {
        data += '<div class="col-12 col-md-6 archivio__results__item" data-num="' + caseHistory.num + '" data-page="' + caseHistory.page + '">'
        data += '<div class="block__cards__card">'
        data += '<div class="block__cards__header">'
        data += '<h3 class="block__cards__subtitle small">' + caseHistory.category + '</h3>'
        data += '</div>'
        data += '<div class="block__cards__text ">' + caseHistory.title + '</div>'
        data += '<a href="' + caseHistory.permalink + '"  class="block__cards__link">' + MyAjax.linkLabel + '</a>'
        data += '</div></div>'
    })

    $('.archivio__results__row').append(data)

    dataPagination()

}

export default dataCaseList
