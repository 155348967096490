
/**
 * Init Prod Correlati slick carousel
 */

const sectionProdCorrelati = () => {
    $(() => {
        if ($('.prod__correlati__list')) {
            $('.prod__correlati__list').slick({
                dots: false,
                arrows: false,
                infinite: true,
                autoplay: true,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                ]
            })
        }
    })
}

export default sectionProdCorrelati