
/**
 * Init reel gallery slick carousel
 */

const footerReel = () => {
    $(() => {
        if ($('.reel__gallery')) {
            $('.reel__gallery').slick({
                dots: false,
                arrows: false,
                infinite: true,
                autoplay: true,
                speed: 300,
                slidesToShow: 4,
                slidesToScroll: 1,
                rows: 0,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            })
        }
    })
}

export default footerReel