const loginForm = () => {
  $(() => {
    const loginForm = document.getElementById("client_login_form");
    if (loginForm) {
      const loginUsername = loginForm.querySelector("#user_login");
      const loginPassword = loginForm.querySelector("#user_pass");
      const loginSubmit = loginForm.querySelector("#wp-submit");

      // Function to check input fields and enable/disable the submit button
      const toggleSubmitButton = () => {
        loginSubmit.disabled = !(loginUsername.value.trim() && loginPassword.value.trim());
      };

      // Add event listeners to check field changes
      loginUsername.addEventListener("input", toggleSubmitButton);
      loginPassword.addEventListener("input", toggleSubmitButton);

      // Initialize state on page load
      toggleSubmitButton();
    }

    const registerForm = document.getElementById("register-form");
    if (registerForm) {
      const registerUsername = registerForm.querySelector("#user_register");
      const registerEmail = registerForm.querySelector("#user_email");
      const registerPassword = registerForm.querySelector("#user_password");
      const registerSubmit = registerForm.querySelector("#register_submit");

      // Function to check input fields and enable/disable the submit button
      const toggleSubmitButton = () => {
        registerSubmit.disabled = !(registerUsername.value.trim() && registerEmail.value.trim() && registerPassword.value.trim());
      };

      // Add event listeners to check field changes
      registerUsername.addEventListener("input", toggleSubmitButton);
      registerEmail.addEventListener("input", toggleSubmitButton);
      registerPassword.addEventListener("input", toggleSubmitButton);

      // Initialize state on page load
      toggleSubmitButton();
    }
  });
};

export default loginForm;
