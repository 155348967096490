/**
 * Run checkboxAnimation for every checkbox
 */

const footerForm = () => {
    let privacy = document.querySelector('.form__privacy')
        , data = document.querySelector('.form__data')

    if (privacy) {
        checkboxAnimation(privacy)
    }
    if (data) {
        checkboxAnimation(data)
    }
}

/**
 * Assign "checked" class to form checkbox container
 * 
 * @param Html selector 
 */

const checkboxAnimation = (selector) => {
    let check = selector.querySelector('.form__checkbox')
    
    if (check) {
        check.addEventListener('click', () => {
            if (check.checked) {
                selector.classList.add('checked')
            } else {
                selector.classList.remove('checked')
            }
        })
    }
}

export default footerForm