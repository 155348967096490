import dataPagination from "./dataPagination"

/**
 * Append Ajax Response data to default container
 * 
 * @param array responseData 
 */

const dataList = (responseData) => {
    let data = ''
    responseData.forEach(prod => {
        let display = (prod.page == 1) ? '' : ' d-none'
        data += '<div class="col-12 col-md-4 archivio__results__item' + display + '" data-num="' + prod.num + '" data-page="' + prod.page + '">'
        data += '<a href="' + prod.permalink + '" class="archivio__results__link">'
        if (prod.image) {
            data += '<img src="' + prod.image.url + '" alt="' + prod.title + '" title="' + prod.title + '" width="' + prod.image.width + '" height="' + prod.image.height + '" loading="lazy" class="archivio__results__image">'
        } else {
            data += '<div class="archivio__results__placeholder"></div>'
        }
        data += '<h3 class="archivio__results__subtitle">'
        if (prod.subtitle) {
            data += prod.subtitle + '<br>'
        }
        data += prod.title + '</h3></a></div>'
    })

    $('.archivio__results__row').append(data)

    dataPagination()

}

export default dataList