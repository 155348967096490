const homeIntro = () => {

    let videos = document.querySelectorAll('.block__home__intro__video')

    if (videos.length > 0) {

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

        videos.forEach(video => {
            if (isMobile) {
                video.addEventListener('click', () => {
                    if (video.paused) {
                        video.play()
                    } else {
                        video.pause()
                    }
                })
            } else {
                video.addEventListener('mouseenter', () => {
                    video.play()
                })
                video.addEventListener('mouseleave', () => {
                    video.pause()
                })
            }
        })
    }

}

export default homeIntro