import $ from "jquery";

window.jQuery = $;
window.$ = $;

import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

window.gsap = gsap;
window.ScrollTrigger = ScrollTrigger;

import {
  // Alert,       // Dismissible alerts
  Button, // Toggle button states
  Carousel, // Slideshow component
  Collapse, // Accordion-like collapsing elements
  Dropdown, // Dropdown menus
  Modal, // Popup modals
  Offcanvas, // Offcanvas sidebars
  // Popover,     // Pop-up content on hover/click (requires @popperjs/core)
  // ScrollSpy,   // Auto-updating navigation based on scroll position
  Tab, // Tabs and pills navigation
  // Toast,       // Small pop-up notifications
  // Tooltip      // Tooltips for elements (requires @popperjs/core)
} from "bootstrap";

import themeMedia from "./theme-media";
themeMedia();

import animations from "./animations";
animations();

import footerReel from "./footer-reel";
footerReel();

import footerForm from "./footer-form";
footerForm();

import loginForm from "./login-form";
loginForm();

import sectionProdTabs from "./product/section-prod-Tabs";
sectionProdTabs();

import sectionProdCorrelati from "./product/section-prod-correlati";
sectionProdCorrelati();

import sectionProdArchiveFilter from "./product/section-prod-archive-filter";
sectionProdArchiveFilter();

import sectionProdInfoGallery from "./product/section-prod-info-gallery";
sectionProdInfoGallery();

import homeIntro from "./home-intro";
homeIntro();
