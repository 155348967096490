/**
 * Data Pagination for Ajax Call 
 */

const dataPagination = () => {
    let items = document.querySelectorAll('.archivio__results__item')
        , pagination = document.querySelector('.archivio__pagination')
        , total = parseInt(items[items.length - 1].dataset.page)

    if (items.length > 1 && total > 1) {
        let data = '<button id="btn__prev" class="archivio__pagination__btn d-none" data-page="prev">&lt;</button>'
        for (let i = 1; i <= total; i++) {
            let current = (i === 1) ? ' current' : ''
            data += '<button class="archivio__pagination__btn' + current + '" data-page="' + i + '">' + i + '</button>'
        }
        data += '<button id="btn__next" class="archivio__pagination__btn" data-page="next">&gt;</button>'
        $('.archivio__pagination').append(data)

        pagination.dataset.current = parseInt(1)
        let btns = document.querySelectorAll('.archivio__pagination__btn')
            , nextBtn = document.getElementById('btn__next')
            , prevBtn = document.getElementById('btn__prev')

        if (btns) {
            btns.forEach(btn => {
                btn.addEventListener('click', () => {
                    if (btn.dataset.page != 'next' && btn.dataset.page != 'prev') {
                        if (pagination.dataset.current != btn.dataset.page) {
                            pagination.dataset.current = btn.dataset.page
                            animateBtns(btns, pagination.dataset.current, items, total)
                        }
                    }

                    $('html, body').animate({
                        scrollTop: $('.archivio__results').offset().top - 280 
                    }, 500);
                })
            })
        }

        if (nextBtn) {
            nextBtn.addEventListener('click', () => {
                if (parseInt(pagination.dataset.current) < total) {
                    pagination.dataset.current = parseInt(pagination.dataset.current) + 1
                    animateBtns(btns, pagination.dataset.current, items, total)
                }
            })
        }

        if (prevBtn) {
            prevBtn.addEventListener('click', () => {
                if (parseInt(pagination.dataset.current) > 1) {
                    pagination.dataset.current = parseInt(pagination.dataset.current) - 1
                    animateBtns(btns, pagination.dataset.current, items, total)
                }
            })
        }
    }
}

export default dataPagination

/**
 * Animate PAgination Buttons
 * 
 * @param {*} btns 
 * @param {*} current 
 * @param {*} items 
 */

function animateBtns(btns, current, items, total) {
    let nextBtn = document.getElementById('btn__next')
    , prevBtn = document.getElementById('btn__prev')
    btns.forEach(b => {
        b.classList.remove('current')
        if (b.dataset.page === current) {
            b.classList.add('current')
        }
    })
    items.forEach(item => {
        item.classList.add('d-none')
        if (item.dataset.page === current) {
            item.classList.remove('d-none')
        }
    })
    if (parseInt(current) <= 1) {
        prevBtn.classList.add('d-none')
    } else {
        prevBtn.classList.remove('d-none')
    }
    if (parseInt(current) >= total) {
        nextBtn.classList.add('d-none')
    } else {
        nextBtn.classList.remove('d-none')
    }
}