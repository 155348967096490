import dataFilter from '../ajax/dataFilter'

/**
 * Init Ajax Filter
 */

const sectionProdArchiveFilter = () => {

    let prodResultSection = document.querySelector('.product__results')
        , caseHistoryResultSection = document.querySelector('.case__history__results')
        , header = document.querySelector('.header__archivio__prodotti')

    if (prodResultSection) {

        // Product Filters

        let dataSector = (header.dataset.settore) ? header.dataset.settore : null
            , frequenzeSelect = document.getElementById('frequenze__select')
            , frequenzeOptions = document.querySelectorAll('#frequenze__select option')
            , deviceSelectWrap = document.getElementById('device__wrap')
            , deviceSelect = document.getElementById('device__select')
            , resetProdBtn = document.getElementById('reset__btn')
            , deviceOptions = document.querySelectorAll('#device__select option')
            , sectorSelect = document.getElementById('settore__select')
            , searchForm = document.getElementById('search__form')
            , searchTerm = searchForm.querySelector('.search__form__input')
            , frequenzaId = (header.dataset.frequenza) ? header.dataset.frequenza : null


        if (frequenzaId) {
            frequenzeOptions.forEach((option, index) => {
                if (frequenzaId === option.value) {
                    frequenzeSelect.selectedIndex = index
                    var event = new Event('change', {
                        bubbles: true,
                        cancelable: true
                    })
                    frequenzeSelect.dispatchEvent(event)
                    frequenzeSelectTrigger(deviceSelect, deviceSelectWrap, searchTerm, frequenzeSelect, dataSector, deviceOptions)
                }
            })
        } else {
            // Init Filtrs 
            dataFilter({
                type: 'prodotto',
                sector: dataSector,
            })
        }

        // Reset filters Button
        if (resetProdBtn) {
            resetProdBtn.addEventListener('click', (e) => {
                e.preventDefault()
                resetProdFilters(frequenzeSelect, deviceSelect, deviceSelectWrap, dataSector, searchTerm)
            })
        }

        // Frequenze Select
        if (frequenzeSelect) {
            frequenzeSelect.addEventListener('change', (e) => {
                e.preventDefault()
                if (frequenzeSelect.value === 'any') {
                    resetProdFilters(frequenzeSelect, deviceSelect, deviceSelectWrap, dataSector, searchTerm)
                } else {
                    frequenzeSelectTrigger(deviceSelect, deviceSelectWrap, searchTerm, frequenzeSelect, dataSector, deviceOptions)
                }
            })
        }

        // Device Select
        if (deviceSelect) {
            deviceSelect.addEventListener('change', (e) => {
                e.preventDefault()
                if (deviceSelect.value === 'any') {
                    resetProdFilters(frequenzeSelect, deviceSelect, deviceSelectWrap, dataSector, searchTerm)
                } else {
                    dataFilter({
                        type: 'prodotto',
                        frequenza: frequenzeSelect.value,
                        device: deviceSelect.value,
                        sector: dataSector,
                    })
                }
            })
        }

        // Settore Select
        if (sectorSelect) {
            sectorSelect.addEventListener('change', (e) => {
                e.preventDefault()
                if (sectorSelect.value != dataSector) {
                    window.location.href = window.location.origin + window.location.pathname + '?settore=' + sectorSelect.value
                }
            })
        }

        // Search Field
        if (searchForm) {
            searchForm.addEventListener('submit', (e) => {
                e.preventDefault()
                if (searchTerm.value && searchTerm.value != '') {
                    if (frequenzeSelect) {
                        frequenzeSelect.selectedIndex = 0
                    }
                    if (deviceSelect) {
                        deviceSelect.selectedIndex = 0
                        deviceSelectWrap.classList.add('disabled')
                        deviceSelect.disabled = true
                    }
                    dataFilter({
                        type: 'prodotto',
                        search: searchTerm.value,
                        sector: dataSector,
                    })
                } else {
                    resetProdFilters(frequenzeSelect, deviceSelect, deviceSelectWrap, dataSector, searchTerm)
                }
            })
        }


    } else if (caseHistoryResultSection) {

        let caseBtns = document.querySelectorAll('.case__history__btn')

        // Init Case History Filtrs 
        dataFilter({
            type: 'case-history'
        })

        // Case History Btns
        if (caseBtns) {
            caseBtns.forEach(btn => {
                btn.addEventListener('click', () => {
                    if (btn.dataset.case === 'any') {
                        dataFilter({
                            type: 'case-history'
                        })
                    } else {
                        dataFilter({
                            type: 'case-history',
                            category: btn.dataset.case
                        })
                    }
                    caseBtns.forEach(button => {
                        button.classList.remove('selected')
                    })
                    btn.classList.add('selected')
                })
            })
        }
    }
}

export default sectionProdArchiveFilter

/**
 * 
 * Trigger Frequenze Select
 * 
 * @param {*} deviceSelect 
 * @param {*} deviceSelectWrap 
 * @param {*} searchTerm 
 * @param {*} frequenzeSelect 
 * @param {*} dataSector 
 * @param {*} deviceOptions 
 */

const frequenzeSelectTrigger = (deviceSelect, deviceSelectWrap, searchTerm, frequenzeSelect, dataSector, deviceOptions) => {
    if (deviceSelect) {
        deviceSelect.selectedIndex = 0
        deviceSelectWrap.classList.add('disabled')
        deviceSelect.disabled = true
    }
    searchTerm.value = ''
    dataFilter({
        type: 'prodotto',
        frequenza: frequenzeSelect.value,
        sector: dataSector,
    })

    deviceOptions.forEach(option => {
        if (option.value != 'any') {
            if (option.classList.contains('frequenza_' + frequenzeSelect.value)) {
                option.classList.remove('d-none')
                deviceSelectWrap.classList.remove('disabled')
                deviceSelect.disabled = false
            } else {
                option.classList.add('d-none')
            }
        }
    })
}

/**
 * 
 * Reset Prod filters
 * 
 * @param {*} frequenzeSelect 
 * @param {*} deviceSelect 
 * @param {*} deviceSelectWrap 
 * @param {*} dataSector 
 * @param {*} searchTerm 
 */

const resetProdFilters = (frequenzeSelect, deviceSelect, deviceSelectWrap, dataSector, searchTerm) => {
    if (frequenzeSelect) {
        frequenzeSelect.selectedIndex = 0
    }
    if (deviceSelect) {
        deviceSelect.selectedIndex = 0
        deviceSelectWrap.classList.add('disabled')
        deviceSelect.disabled = true
    }
    searchTerm.value = ''
    dataFilter({
        type: 'prodotto',
        frequenza: null,
        device: null,
        search: null,
        sector: dataSector,
        category: null
    })
}
