
/**
 * Init Prod info gallery slick carousel
 */

const sectionProdInfoGallery = () => {
    $(() => {
        if ($('.prod__info__gallery__nav') && $('.prod__info__gallery__detail')) {
            $('.prod__info__gallery__detail').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                arrows: false,
                fade: true,
                asNavFor: '.prod__info__gallery__nav'
            })

            $('.prod__info__gallery__nav').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                vertical: true,
                dots: false,
                arrows: true,
                asNavFor: '.prod__info__gallery__detail',
                // centerMode: true,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            vertical: false,
                        }
                    },
                ]
            })

            $('.prod__info__gallery__detail').css('opacity', 1)
            $('.prod__info__gallery__nav').css('opacity', 1)
        }
    })
}

export default sectionProdInfoGallery