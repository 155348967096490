const animations = () => {
  gsap.registerPlugin(ScrollTrigger);
  let tl = gsap.timeline();

  fadeInAtLoading(tl, ".navbar");
  fadeInAtLoading(tl, ".header__intro");
  fadeInAtLoading(tl, ".header__title");
  fadeInAtLoading(tl, ".header__subtitle");
  fadeInAtLoading(tl, ".header__link");

  fadeInAtScroll("block__banner", ["__icon", "__title", "__link"]);
  fadeInAtScroll("block__home__intro", ["__title", "__card", "__text"]);
  fadeInAtScroll("block__cards", ["__title", "__section__text", "__card"]);
  fadeInAtScroll("block__accordion", ["__title", "__accordion"]);
  fadeInAtScroll("block__timeline", ["__title", "__timeline__step"]);
  fadeInAtScroll("form", ["__title", "__text"]);
};

export default animations;

/**
 * Fade in as Page load
 *
 * @param {*} tl
 * @param string selector
 */

const fadeInAtLoading = (tl, selector) => {
  let elements = document.querySelectorAll(selector);
  if (elements.length > 0) {
    let animation = {
      opacity: 1,
      duration: 0.3,
      ease: "power1",
    };
    elements.forEach((item) => {
      tl.to(item, animation);
    });
  }
};

/**
 * Fade in at scroll
 *
 * @param string prefix
 * @param array children
 */

const fadeInAtScroll = (prefix, children) => {
  const sections = document.querySelectorAll(`.${prefix}__section`);
  if (!sections) return;

  sections.forEach((section) => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top bottom-=15%",
        end: "bottom 50%",
        // markers: true,
      },
    });

    children.forEach((childClass) => {
      const elements = section.querySelectorAll(`.${prefix}${childClass}`);
      if (!elements) return;

      elements.forEach((element) => {
        timeline.fromTo(
          element,
          {
            opacity: 0,
            y: "50px",
          },
          {
            opacity: 1,
            y: "0",
            duration: 0.2,
            ease: "power2.out",
          }
        );
      });
    });
  });
};
