/**
 * Tabs Buttons Class
 */
const sectionProdTabs = () => {

    let tabButtons = document.querySelectorAll('.prod__tabs__btn')
        , tabTexts = document.querySelectorAll('.prod__tabs__text')


    if (tabButtons.length > 0) {
        tabButtons[0].classList.add('active')
    }

    if (tabTexts.length > 0) { 
        tabTexts[0].classList.add('active')
        tabTexts[0].classList.add('show')
    }
    
}

export default sectionProdTabs