/**
 * Clear all Ajax call results
 */

const dataClear = () => {
    let cols = document.querySelectorAll('.archivio__results__item')
        , pagination = document.querySelectorAll('.archivio__pagination__btn')
    cols.forEach(col => {
        col.remove()
    })
    pagination.forEach(btn => {
        btn.remove()
    })
}

export default dataClear