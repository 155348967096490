import dataList from "./dataList"
import dataCaseList from "./dataCaseList"
import dataClear from "./dataClear"

/**
 * Ajax call with filter parameters
 * 
 * @param object filter 
 */

const dataFilter = (filter) => {
    // console.log(filter)

    dataClear()

    let loader = document.getElementById('loader__section')
    if(loader) {
        loader.classList.remove('d-none')
    }

    // Data
    let data = {
        action: 'my_action',
        security: MyAjax.security,
        type: filter.type,
        frequenza: filter.frequenza,
        device: filter.device,
        search: filter.search,
        sector: filter.sector,
        category: filter.category
    }

    // Ajax Call
    $.post({
        url: MyAjax.ajaxurl,
        data: data,
        success: (res) => {
            if (res.data === "empty") {
                if(loader) {
                    loader.classList.add('d-none')
                }
                console.log('Ajax call had no results.')
            } else {
                // console.log(res)

                if (filter.type === 'prodotto') {

                    dataList(res.data)
                } else if (filter.type === 'case-history') {
                    dataCaseList(res.data)
                }
                if(loader) {
                    loader.classList.add('d-none')
                }
                console.log('Ajax call had ' + res.data.length + ' results.')
            }
        },
        error: (e) => {
            console.log('Errore ' + e.status + ': ' + e.statusText)
        }
    })

}

export default dataFilter